
import { productList, fetchProductList } from '@/states/sales'
import { deleteProduct } from '@/api/sales'
import { Dialog } from 'vant'
import { fromNow } from '@/utils/format'
import { useRouter } from 'vue-router'

export default {
  setup: () => {
    const router = useRouter()
    fetchProductList(64)
    return {
      productList,
      fromNow,
      edit: (id: string) => {
        router.push('/admin/sales/detail/' + id)
      },
      deleteFn: (id: string, i: number) => {
        Dialog.confirm({
          title: '确认删除',
          message: '建议先设置成失效状态'
        }).then(() => {
          deleteProduct(id).then(() => {
            productList.value.splice(i, 1)
          })

          // on confirm
        }).catch(() => {
          // on cancel
        })
      }
    }
  }
}
